.personal-list {
  margin-top: 60px;
}

.personal-list__title {
  color: $main;
  font-family: "NAMU";
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
}

.personal-list__items {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.personal-list__item {
  padding: 24px;
  margin-bottom: 24px;
  border: 2px solid $inner-bg;
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.personal-list__item-name {
  color: #172331;
  font-family: "NAMU";
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
}

.personal-list__item-footer {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
}

.personal-list__item-text {
  color: $text-secondary;
  font-family: "Manrope";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  margin-right: 30px;
}

.personal-list__item-control {
  display: flex;
}

.personal-list__item-btn {
  display: block;
  text-align: center;
  padding: 12px;
  width: 200px;

  color: #fff;
  font-family: "Manrope";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  margin-right: 16px;
  &::after {
    display: none;
  }
  &:active {
    transform: scale(0.97);
  }
}

@media (max-width: map-get($tablet, lg)) {
  .personal-list__item {
    padding: 12px 16px 12px;
  }
  .personal-list__item-footer {
    margin-top: 6px;
  }
}

@media (max-width: map-get($tablet, md)) {
  .personal-list__item {
    flex-direction: column;
    align-items: flex-start;
  }

  .personal-list__item-footer {
    display: flex;
    flex-direction: column;
    margin-top: 6px;
  }

  .personal-list__item-control {
    margin-top: 30px;
    align-self: flex-end;
  }
  .personal-list__item-btn {
    width: 180px;
    padding: 8px;
  }
}
