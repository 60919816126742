$main: #172331;
$secondary: #214875;

$inner-bg: #f5f4f5;

$tarrifs-bg: #e3e5ee;
$tarrifs-active: #287bdb;

$info: #a8aae7;
$info-bg: #e0e1ff;

$success: #4db552;
$success-secondary: #a7c9bc;
$success-secondary-bg: #e5f1ed;

$text-main: #657383;
$text-secondary: #515e6f;

$error: #d62912;
