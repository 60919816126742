.policy {
  margin: 80px auto;
  h1 {
    color: $main;
    font-family: "NAMU";
    font-size: 25px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    margin-bottom: 30px;
  }
  h2 {
    color: $main;
    font-family: "Manrope";
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    margin-bottom: 24px;
  }
  p {
    color: $text-secondary;
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    margin-bottom: 16px;
  }
}
