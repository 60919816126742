.promo-features {
  width: 49%;
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
}
.promo-feature {
  padding: 32px 20px;
  width: 48%;
  background: $success-secondary-bg;

  display: flex;
  flex-direction: column;
}

.promo-feature__tag {
  color: #fff;
  font-family: "Manrope";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  text-align: center;

  padding: 7px 10px;
  border-radius: 60px;
  background: $success-secondary;
  width: fit-content;
}

.promo-feature__title {
  color: $main;
  font-family: NAMU;
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;

  margin-top: 20px;
}

.promo-feature__list {
  margin-top: 13px;

  li {
    padding-left: 14px;
    margin-bottom: 1px;

    color: $text-secondary;
    font-family: "Manrope";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 9px;
      display: block;
      width: 9px;
      height: 1px;
      background-color: $text-secondary;
    }
  }
}

.promo-feature__btn {
  display: flex;
  justify-content: space-between;
  background: $main;
  border: none;
  cursor: pointer;

  padding: 9px 20px;
  margin-top: auto;
  svg {
    width: 16px;
    height: 16px;
    transition: all 0.25s;
    transform: translate(0px, 2px);
  }
  span {
    color: #fff;
    font-family: "Manrope";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  transition: all 0.25s;
  &:hover {
    background-color: $secondary;
    svg {
      transform: translate(5px, 2px);
    }
  }
  &:active {
    transform: scale(0.95);
  }
}

@media (max-width: map-get($laptop, sm)) {
  .promo-features {
    width: 100%;
    margin-top: 33px;
    height: 410px;
  }
  .promo-feature {
    width: 48.5%;
  }
  .promo-feature__list {
    max-width: 89%;
  }
}

@media (max-width: map-get($tablet, md)) {
  .promo-features {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    height: auto;
  }
  .promo-feature {
    width: 100%;
    &:first-child {
      margin-bottom: 20px;
    }
  }
  .promo-feature__btn {
    margin-top: 32px;
  }
}

@media (max-width: map-get($tablet, md)) {
  .promo-feature {
    padding: 15px 13px;
  }
  .promo-feature__tag {
    margin-top: 6px;
    // margin-left: 5px;
  }
  .promo-feature__title {
    font-size: 20px;
  }
  .promo-feature__list {
    margin-top: 14px;
    max-width: unset;
    li {
      font-size: 14px;
    }
  }
  .promo-feature__btn {
    margin-top: 32px;
  }
}
