.promo-banner {
  width: 49%;
  height: 400px;
}

.promo-banner__image {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

@media (max-width: map-get($laptop, sm)) {
  .promo-banner {
    width: 100%;
    height: 52vw;
    max-height: 600px;
    margin-top: 24px;
  }
}

@media (max-width: map-get($tablet, md)) {
  .promo-banner {
    margin-top: 33px;
    height: 77vw;
  }
}
