.container-full {
  max-width: 1920px;
  width: 100%;
  padding: 0 80px;
  margin-left: auto;
  margin-right: auto;
}
.container {
  max-width: 1440px;
  width: 100%;
  padding: 0 80px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: map-get($tablet, lg)) {
  .container {
    padding: 0 30px;
  }
}

@media (max-width: map-get($tablet, md)) {
  .container {
    padding: 0 15px;
  }
}
