.main-personal {
  margin-top: 40px;
  margin-bottom: 120px;
}

.main-personal__container {
  display: flex;
  flex-direction: column;
}

@media (max-width: map-get($tablet, lg)) {
  .main-personal {
    margin-top: 10px;
    margin-bottom: 80px;
  }
}

@media (max-width: map-get($tablet, md)) {
  .main-personal {
    margin-top: 25px;
    margin-bottom: 74px;
  }
}
