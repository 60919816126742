.tarrifs {
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  margin-bottom: 120px;
}

.tarrifs__title {
  color: $main;
  text-align: center;
  font-family: "NAMU";
  font-size: 52px;
  font-style: normal;
  font-weight: 800;
  line-height: 110%;

  text-align: center;
}

.tarrifs__tabs {
  display: flex;
  justify-content: center;

  margin-top: 40px;
}

.tarrifs__tabs-wrap {
  border-radius: 8px;
  background: $tarrifs-bg;
}

.tarrifs__tab {
  background: $tarrifs-bg;
  border: none;
  outline: none;
  padding: 13px 24px;
  border-radius: 8px;

  margin-right: 4px;
  &:last-child {
    margin-right: 0;
  }

  color: $text-secondary;
  text-align: center;
  font-family: "Manrope";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;

  cursor: pointer;
  transition: all 0.25s;
  &--active {
    background: $tarrifs-active;
    color: #fff;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);
  }
}

.tarrifs__items {
  display: flex;
  justify-content: space-between;

  margin-top: 60px;

  max-width: 1440px;
  width: 100%;
  padding: 0 80px;
  margin-left: auto;
  margin-right: auto;
}

.tarrifs__item {
  width: 32%;
  border: 1px solid $tarrifs-bg;
  background: #fff;
  box-shadow: 0px 8px 22px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.tarrifs__item-header {
  padding: 30px 36px;
  min-height: 239px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $tarrifs-bg;
}

.tarrifs__item-title {
  color: $main;
  font-family: "NAMU";
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
}

.tarrifs__item-desc {
  color: $text-main;
  font-family: "Manrope";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;

  display: block;
  margin-top: 6px;
}

.tarrifs__item-price {
  margin-top: 30px;

  span {
    color: $text-main;
    font-family: "Manrope";
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
  .tarrifs__item-price-num {
    color: $main;
    font-family: "Manrope";
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;

    margin-right: 8px;
  }
}

.tarrifs__item-btn {
  width: 100%;
  border: 1px solid $main;
  background: #fff;
  padding: 12px;
  // margin-top: 8px;
  margin-top: auto;
  display: block;
  color: $main;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.25s;

  &:hover {
    background-color: $tarrifs-bg;
    color: $main;
  }
  &:active {
    transform: scale(0.95);
  }

  &--accent {
    background: $main;
    color: #fff;
  }
}

.tarrifs__item-body {
  padding: 30px 0px 36px;
  display: flex;
  flex-grow: 1;
}

.tarrifs__item-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  li {
    margin-bottom: 12px;
  }
}

.tarrifs__item-feature {
  display: flex;
  align-items: center;

  padding: 0 36px;

  &:last-child {
    margin-bottom: 0;
  }

  &--off {
    .tarrifs__item-feature-name {
      color: $text-main;
    }
  }

  &--next-off {
    margin-bottom: 25px;
  }
}

.tarrifs__item-feature-icon {
  width: 20px;
  height: 20px;

  margin-right: 10px;
}

.tarrifs__item-feature-name {
  color: $main;
  font-family: "Manrope";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.tarrifs__item-feature-status {
  width: 22px;
  height: 22px;

  margin-left: auto;
}

.tarrifs__item-divider {
  width: 100%;
  height: 1px;
  background-color: $tarrifs-bg;
  transform: translateY(-5px);
}

@media (max-width: map-get($laptop, sm)) {
  .tarrifs {
    margin-bottom: 60px;
  }
  .tarrifs__items {
    overflow: scroll;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    padding: 0 30px 60px;
  }
  .tarrifs__item {
    min-width: 340px;
    margin-right: 12px;
  }
}

@media (max-width: map-get($tablet, md)) {
  .tarrifs {
    margin-bottom: 30px;
  }

  .tarrifs__title {
    font-size: 28px;
    padding: 0 15px;
  }

  .tarrifs__tabs {
    margin-top: 22px;
  }

  .tarrifs__tabs-wrap {
    // width: 288px;
    justify-content: space-between;
    display: flex;
  }

  .tarrifs__tab {
    padding: 13px 8px;
    font-size: 12px;
    margin-right: 0;
  }
  .tarrifs__items {
    margin-top: 32px;
  }
  .tarrifs__item {
    min-width: 288px;
  }
  .tarrifs__item-header {
    padding: 24px 18px 22px;
    min-height: 204px;
  }
  .tarrifs__item-title {
    font-size: 20px;
  }
  .tarrifs__item-desc {
    font-size: 13px;
  }
  .tarrifs__item-price {
    margin-top: 22px;
    span {
      font-size: 14px;
    }
    .tarrifs__item-price-num {
      font-size: 26px;
    }
  }
  .tarrifs__item-btn {
    // margin-top: 12px;
    padding: 11px;
  }

  .tarrifs__item-feature {
    padding: 0 18px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .tarrifs__item-feature-icon,
  .tarrifs__item-feature-status {
    width: 18px;
    height: 18px;
  }
  .tarrifs__item-feature-name {
    font-size: 13px;
  }
}
