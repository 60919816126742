.promo-usability {
  background: $info-bg;
  padding: 32px;

  display: flex;
  width: 49%;
  height: 410px;
  margin-top: 100px;
}

.promo-usability__info {
  display: flex;
  flex-direction: column;
  width: 330px;
}

.promo-usability__tag {
  color: #fff;
  font-family: "Manrope";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  text-align: center;

  padding: 7px 10px;
  border-radius: 60px;
  background: $info;
  width: fit-content;
}

.promo-usability__title {
  color: $main;
  font-family: NAMU;
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;

  margin-top: 30px;
}

.promo-usability__text {
  color: $text-secondary;
  font-family: "Manrope";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  margin-top: 22px;
}

.promo-usability__image {
  height: 347px;
  width: 203px;
  margin-left: 32px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

@media (max-width: map-get($laptop, sm)) {
  .promo-usability {
    width: 100%;
    margin-top: 33px;
  }
  .promo-usability__info {
    width: 64%;
  }
}

@media (max-width: map-get($tablet, md)) {
  .promo-usability {
    padding: 20px 15px;
    flex-direction: column;
    height: auto;
    margin-top: 72px;
  }
  .promo-usability__info {
    width: 100%;
  }
  .promo-usability__title {
    font-size: 20px;
    margin-top: 15px;
  }
  .promo-usability__text {
    font-size: 14px;
    margin-top: 15px;
  }
  .promo-usability__image {
    width: 125px;
    height: 212px;
    margin: 32px auto 0px;
  }
}
