.header {
  padding: 19px 0px;
}

.header__container {
  display: flex;
  align-items: center;
}

.header__logo {
  flex: 0 0 auto;
  svg {
    width: 141px;
    height: 24px;
  }
}

.header__nav {
  display: flex;
  margin-left: 340px;
}

.header__nav-link {
  white-space: nowrap;
  &:first-child {
    margin-right: 50px;
  }
}

.header__link {
  font-family: "Manrope";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: $text-secondary;
  text-decoration: none;

  transition: all 0.25s;
  &:hover {
    color: $text-main;
  }
}

.header__login-link {
  margin-left: auto;
}

.header__btn {
  white-space: nowrap;
  margin-left: 20px;
  margin-right: 9px;
  padding: 12px 15px;

  color: #fff;
  font-family: "Manrope";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

@media (max-width: map-get($laptop, md)) {
  .header__container {
    justify-content: space-between;
  }
  .header__nav {
    margin-left: 20%;
  }
  .header__login-link {
    margin-left: auto;
  }
}

@media (max-width: map-get($laptop, sm)) {
  .header__nav-link {
    &:first-child {
      margin-right: 22px;
    }
  }
}

@media (max-width: map-get($tablet, lg)) {
  .header {
    padding: 19px 0px;
  }

  .header__logo {
    svg {
      width: 121px;
      height: 21px;
    }
  }

  .header__nav {
    margin-left: 8%;
  }

  .header__link {
    font-size: 16px;
    transform: translateY(-2px);
  }

  .header__btn {
    font-size: 15px;
    margin-left: 15px;
  }
}

@media (max-width: map-get($tablet, md)) {
  .header {
    padding: 10px 0px;
  }

  .header__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .header__nav {
    order: 0;
    width: auto;
    margin-left: 0;
  }

  .header__nav-link {
    &:first-child {
      margin-right: 25px;
    }
  }

  .header__link {
    font-size: 14px;
  }

  .header__login-link {
    order: 2;
    width: 19%;
    text-align: right;
  }

  .header__logo {
    order: 3;
    width: 50%;
    margin: 22px 0 0;
    svg {
      width: 119px;
      height: 20px;
    }
  }

  .header__btn {
    order: 4;
    width: 126px;
    margin: 20px 0 0;
    padding: 8px 10px;
    font-size: 13px;
  }
}
