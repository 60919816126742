.footer {
  background: $main;
  padding: 45px 0px 40px;
}

.footer__container {
  display: flex;
  align-items: center;
}

.footer__contacts,
.footer__info,
.footer__logo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__contacts-link {
  color: #fff;
  font-family: "Manrope";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  text-decoration: none;

  transition: all 0.25s;
  &:hover {
    color: $text-main;
  }
  &:first-child {
    margin-bottom: 15px;
  }
}

.footer__logo {
  margin-left: 364px;
  svg {
    width: 126px;
    height: 22px;
  }
  a {
    margin-top: 24px;

    color: $text-main;
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-decoration: none;
    white-space: nowrap;

    transition: all 0.25s;
    &:hover {
      color: $text-secondary;
    }
  }
}

.footer__info {
  margin-left: auto;
}

.footer__info-btn {
  padding: 9px 20px;
  border: none;
  border-radius: 0;
  background: $text-secondary;
  cursor: pointer;
  color: #fff;
  font-family: "Manrope";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  transition: all 0.25s;
  &:hover {
    background: $text-main;
  }
  &:active {
    transform: scale(0.95);
  }
}

.footer__info-text {
  margin-top: 16px;

  color: $text-main;
  font-family: "Manrope";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  white-space: nowrap;

  a {
    color: $info-bg;
    opacity: 0.7;
    text-decoration: none;
    cursor: pointer;

    transition: all 0.25s;
    &:hover {
      opacity: 1;
    }
  }
}

@media (max-width: map-get($laptop, md)) {
  .footer__container {
    justify-content: space-between;
  }
  .footer__logo {
    margin-left: 20%;
  }
  .footer__info {
    margin-left: auto;
  }
}

@media (max-width: map-get($tablet, lg)) {
  .footer {
    padding: 40px 0px;
  }

  .footer__logo {
    margin-left: 10%;
    svg {
      width: 126px;
      height: 22px;
    }
  }
}

@media (max-width: map-get($tablet, md)) {
  .footer {
    padding: 32px 0px 40px;
  }
  .footer__container {
    flex-direction: column;
    align-items: center;
  }
  .footer__logo {
    order: 1;
    margin: 0;
    a {
      margin-top: 16px;
    }
  }
  .footer__contacts {
    order: 2;
    margin-top: 32px;
  }
  .footer__contacts-link {
    &:first-child {
      margin-bottom: 7px;
    }
  }
  .footer__info {
    order: 3;
    margin: 32px 0 0;
  }
}
