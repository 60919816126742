.personal-entry__form {
  display: flex;
  position: relative;
  margin-top: 40px;
  width: 82%;
}

.personal-entry__input {
  width: 360px;
  padding: 17px 16px;
  border: 1px solid $text-main;
  border-radius: 0;
  font-family: "Manrope";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.personal-entry__input-error {
  position: absolute;
  bottom: -20px;
  color: $error;
  font-family: "Manrope";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.personal-entry__submit {
  padding: 16px;
  margin-left: 14px;
  width: 200px;

  color: #fff;
  font-family: "Manrope";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  &::after {
    display: none;
  }
  &:active {
    transform: scale(0.97);
  }
}

@media (max-width: map-get($tablet, md)) {
  .personal-entry__form {
    max-width: unset;
    width: 100%;
    flex-direction: column;
    margin-top: 36px;
  }
  .personal-entry__input {
    width: 100%;
    padding: 11px 16px;
  }
  .personal-entry__submit {
    width: 98%;
    padding: 13px;
    margin-top: 17px;
    margin-left: 0;
  }
  .personal-entry__input-error {
    bottom: unset;
    top: -23px;
  }
}
