@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  src: local("Manrope Regular"), local("Manrope-Regular"),
    url("../fonts/Manrope-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  src: local("Manrope Medium"), local("Manrope-Medium"),
    url("../fonts/Manrope-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "NAMU";
  font-style: normal;
  font-weight: 800;
  src: local("NAMU Bold"), local("NAMU-Bold"),
    url("../fonts/NAMU-1990.ttf") format("truetype");
}

// @font-face {
//   font-family: "Manrope";
//   font-style: normal;
//   font-weight: 400;
//   src: local("Manrope Regular"), local("Manrope-Regular"),
//     url("assets/fonts/Manrope-Regular.ttf") format("truetype");
// }
// @font-face {
//   font-family: "Manrope";
//   font-style: normal;
//   font-weight: 500;
//   src: local("Manrope Medium"), local("Manrope-Medium"),
//     url("assets/fonts/Manrope-Medium.ttf") format("truetype");
// }
// @font-face {
//   font-family: "NAMU";
//   font-style: normal;
//   font-weight: 800;
//   src: local("NAMU Bold"), local("NAMU-Bold"),
//     url("assets/fonts/NAMU-1990.ttf") format("truetype");
// }
