.modal {
  display: none;
  justify-content: center;
  align-items: center;

  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;

  &--active {
    display: flex;
  }

  &--success {
    .modal__form {
      justify-content: center;
    }

    .modal__title {
      margin-bottom: 25px;
    }

    .modal__text {
      margin-bottom: 40px;
    }
  }

  &--enter {
    .modal__form {
      min-height: 378px;
    }
  }

  &--create {
    .modal__form {
      min-height: 393px;
    }
  }
}

.modal__backdrop {
  background: $main;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 110;

  animation-name: backdrop-fade;
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes backdrop-fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.8;
  }
}

.modal__form {
  width: 550px;
  min-height: 460px;
  background: #fff;

  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 60px 69px 40px;

  position: relative;
  z-index: 120;

  animation-name: modal-show;
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes modal-show {
  0% {
    transform: scale(0.9) translateY(30px);
  }

  100% {
    transform: scale(1) translateY(0px);
  }
}

.modal__close {
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;

  transition: all 0.25s;

  svg {
    width: 30px;
    height: 30px;
  }

  &:active {
    transform: scale(0.9);
  }
}

.modal__title {
  color: $main;
  text-align: center;
  font-family: "NAMU";
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 110%;

  margin-bottom: 40px;

  &--tarrifs {
    font-size: 28px;
    margin-bottom: 14px;
  }
}

.modal__subtitle {
  color: $text-main;
  text-align: center;
  font-family: "Manrope";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  margin-bottom: 40px;
}

.modal__label {
  width: 100%;
  position: relative;

  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

.modal__input {
  width: 100%;
  padding: 16px;
  color: $text-main;
  font-family: "Manrope";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  border: 1px solid $text-main;
  border-radius: 0;
  outline: none;

  transition: all 0.2s;

  &--error {
    color: $error;
    border: 1px solid $error;
  }
}

.modal__tooltip {
  position: absolute;
  bottom: -20px;
  left: 0;
  color: $error;
  font-family: "Manrope";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  display: none;

  &--active {
    display: block;
  }
}

.modal__btn {
  color: #fff;
  font-family: "Manrope";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  align-self: flex-start;
  padding: 17px 8px;
  width: 97%;
}

.modal__links {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 38px;
}

.modal__link {
  color: $text-secondary;
  font-family: "Manrope";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  text-decoration-line: underline;

  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.modal__text {
  color: $main;
  text-align: center;
  font-family: "NAMU";
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: 110%;

  a {
    text-decoration: underline;
    color: $secondary;
  }
}

.modal__checkbox {
  position: relative;
  cursor: pointer;

  span {
    color: $text-secondary;
    font-family: "Manrope";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    padding-left: 32px;
  }

  &::before {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid $main;
  }

  .modal__checkbox-icon {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 20px;
    height: 20px;
    opacity: 0;
  }

  &--error {
    span {
      color: $error;
    }

    &::before {
      border: 1px solid $error;
    }
  }

  &--disabled {
    opacity: 0.4;
  }
}

.modal__notice {
  color: $text-secondary;
  font-family: "Manrope";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  transform: translateY(-15px);

  a {
    text-decoration: underline;
    color: $secondary;
  }
}

.modal__checkbox-input {
  position: absolute;
  opacity: 0;
}

.modal__checkbox-input:checked~.modal__checkbox-icon {
  opacity: 1;
}

@media (max-width: map-get($tablet, sm)) {
  .modal {
    &--success {
      .modal__title {
        margin-bottom: 20px;
      }

      .modal__text {
        margin-bottom: 30px;
      }
    }

    &--enter {
      .modal__form {
        min-height: 323px;
      }
    }

    &--create {
      .modal__form {
        min-height: 323px;
      }
    }
  }

  .modal__form {
    width: 90%;
    min-height: 371px;
    padding: 50px 22px 40px;
  }

  .modal__close {
    top: 8px;
    right: 8px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .modal__title {
    font-size: 24px;
    margin-bottom: 30px;

    &--tarrifs {
      font-size: 20px;
      margin-bottom: 14px;
    }
  }

  .modal__subtitle {
    font-size: 14px;
    margin-bottom: 30px;
  }

  .modal__label {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .modal__input {
    padding: 12px 16px;
    font-size: 14px;
  }

  .modal__tooltip {
    font-size: 12px;
    bottom: -18px;
  }

  .modal__btn {
    width: 100%;
    padding: 12px 8px;
    font-size: 15px;
  }

  .modal__links {
    margin-top: 30px;
  }

  .modal__link {
    font-size: 14px;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .modal__text {
    font-size: 16px;
  }

  .modal__checkbox {
    span {
      font-size: 15px;
      float: left;
      padding-left: 30px;
    }
  }
}