.promo-entry {
  width: 49%;

  padding: 20px 0 42px;
}

.promo-entry__title {
  color: $main;
  font-family: "NAMU";
  font-size: 52px;
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
}

.promo-entry__text {
  margin-top: 28px;
  max-width: 86%;

  color: $text-secondary;
  font-family: "Manrope";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.promo-entry__form {
  position: relative;
  margin-top: 60px;
  display: flex;
  width: 82%;
}

.promo-entry__input {
  flex-grow: 1;

  border: 1px solid $text-secondary;
  border-radius: 0;
  padding: 17px 16px;
  color: $text-main;
  font-family: "Manrope";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.promo-entry__input-error {
  position: absolute;
  bottom: -20px;
  color: $error;
  font-family: "Manrope";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.promo-entry__submit {
  padding: 16px;
  margin-left: 14px;
  width: 200px;

  color: #fff;
  font-family: "Manrope";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

@media (max-width: map-get($laptop, sm)) {
  .promo-entry {
    width: 100%;
  }

  .promo-entry__text {
    max-width: 75%;
  }

  .promo-entry__form {
    max-width: 72%;
    margin-top: 62px;
  }

  .promo-entry__submit {
    width: 196px;
  }
}

@media (max-width: map-get($tablet, md)) {
  .promo-entry {
    padding: 20px 0 0;
  }
  .promo-entry__title {
    font-size: 28px;
    text-align: center;
  }
  .promo-entry__text {
    max-width: unset;

    font-size: 14px;
    margin-top: 22px;
    text-align: center;
  }
  .promo-entry__form {
    max-width: unset;
    width: 100%;
    flex-direction: column;
    margin-top: 36px;
  }
  .promo-entry__input {
    width: 100%;
    padding: 11px 16px;
  }
  .promo-entry__submit {
    width: 97%;
    padding: 13px;
    margin-top: 17px;
    margin-left: 0;
  }
  .promo-entry__input-error {
    bottom: unset;
    top: -23px;
  }
}
