.btn {
  border-radius: 0;
  border: none;
  background: $main;
  color: #fff;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    border: 1px solid $main;
    left: 0;
    top: 0;
    z-index: -1;
    transform: translate(7px, 8px);
    transition: all 0.25s;
  }

  transition: all 0.25s;
  &:hover {
    background-color: $secondary;
  }
  &:active {
    transform: translate(7px, 8px);
    &::after {
      transform: translate(-1px, -1px);
    }
  }

  &--success {
    background: $success;
    &::after {
      border: 1px solid $success;
    }
    &:hover {
      background-color: $success-secondary;
    }
  }

  &--secondary {
    background: $secondary;
    &::after {
      border: 1px solid $secondary;
    }
    &:hover {
      background-color: $text-secondary;
    }
  }
}

@media (max-width: map-get($tablet, md)) {
  .btn {
    &::after {
      transform: translate(3px, 2px);
    }
    &:active {
      transform: translate(3px, 2px);
      &::after {
        transform: translate(-1px, -1px);
      }
    }
  }
}
