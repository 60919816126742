.main-promo {
  margin-top: 40px;
  margin-bottom: 120px;
}

.main-promo__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: map-get($tablet, lg)) {
  .main-promo {
    margin-top: 10px;
    margin-bottom: 80px;
  }
}

@media (max-width: map-get($tablet, md)) {
  .main-promo {
    margin-top: 25px;
    margin-bottom: 74px;
  }
}
